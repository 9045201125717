<template>
  <!-- 商品列表 -->
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="商品列表"
    ></el-page-header>
    <div class="select-container">
      <div class="select">
        <div class="selectVal">
          <div class="text">查找商品：</div>
          <el-input placeholder="请输入商品名称" v-model="inputData" clearable>
          </el-input>
        </div>
        <el-button
          type="primary"
          class="search"
          icon="el-icon-search"
          :disabled="buttonDisabled"
          @click="searchButton"
          >搜索</el-button
        >
        <el-button type="success" @click="allProduct">全部商品</el-button>
      </div>
      <div class="product-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :data="tableData"
          border
          style="width: 100%; max-width: 100%"
          :cell-style="csllStyle"
        >
          <!-- <el-table-column fixed prop="Id" label="序号" width="50%"> -->
          <el-table-column
            fixed
            type="index"
            :index="pagesize * page_num - pagesize + 1"
            label="序号"
            width="50%"
          >
          </el-table-column>
          <el-table-column prop="title" label="商品名称" width="260%">
          </el-table-column>
          <el-table-column prop="amount" label="商品数量" width="100%">
          </el-table-column>
          <el-table-column prop="unit" label="商品单位" width="100%">
          </el-table-column>
          <el-table-column prop="inPrice" label="入库价" width="150%">
          </el-table-column>
          <el-table-column prop="outPrice" label="出库价" width="150%">
          </el-table-column>
          <el-table-column prop="belongToVip" label="所属会员" width="130%">
          </el-table-column>
          <el-table-column prop="store" label="店铺" width="200%">
          </el-table-column>
          <el-table-column prop="inDB" label="入库时间" width="200%">
          </el-table-column>
          <el-table-column prop="lastfixDB" label="最后修改时间" width="200%">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180%">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button type="text" @click="compile(scope.row)" size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页底部 -->
        <el-pagination
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next"
          :total="totalNum"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data: function () {
    return {
      data: "",
      tableData: "",
      value: "title",
      inputData: "",
      buttonDisabled: true,
      // 是否加载数据
      loading: true,
      currentPage: 1,
      pagesize: 10,
      files_count: 5,
      // fileList: [],
      searchFile: "",
      totalNum: 0,
      page_num: 1,
      userKey: '',
    };
  },
  created() {
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"))
    let pageNum = 1;
    let userKey = this.userKey
    axios.post(this.GLOBAL.api + "/productList", { pageNum, userKey }).then((res) => {
      this.loading = false;
      this.totalNum = res.data.total[0].count;
      this.tableData = res.data.val;
    });
  },
  // 监听器
  watch: {
    inputData: function () {
      this.buttonDisabled =
        this.inputData !== "" || this.inputData ? false : true;
    },
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    handleClick(row) {
      const id = row.productID;
      this.$router.push(`/compile/${id}`);
    },
    handleCurrentChange(val) {
      let pageNum = val;
      this.page_num = val;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/productList", { pageNum, userKey }).then((res) => {
        this.totalNum = res.data.total[0].count;
        this.tableData = res.data.val;
      });
    },
    searchButton() {
      let title = this.inputData;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/productList", { title, userKey}).then((res) => {
        this.totalNum = res.data.total[0].count;
        this.tableData = res.data.val;
      });
    },
    compile(e) {
      this.$confirm("是否确定删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          const productId = e.productID;
          axios.post(this.GLOBAL.api + "/delete/product", { productId }).then(() => {
            this.$message({
              type: "info",
              message: "删除成功",
            });
            this.reload();
          });
        })
        .catch(() => {
          // this.$router.push("/member/list");
        });
    },
    allProduct() {
      this.inputData = "";
      let pageNum = 1;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api+"/productList", { pageNum, userKey }).then((res) => {
        this.totalNum = res.data.total[0].count;
        this.tableData = res.data.val;
      });
    },
    // amount
    csllStyle(row) {
      // 监听tabel数据进行操作
      if (row.row.amount < 1) {
        return 'backgroundColor:rgb(167 41 41);color:#FFF'
      }
    }
  },
};
</script>
<style scoped>
.box-card {
  width: 100%;
}
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
}
.select {
  width: 100%;
  margin-top: 20px;
  display: flex;
}
.selectVal {
  display: flex;
  width: 30%;
  margin-left: 30px;
}
.product-table {
  width: 100%;
}
.search {
  margin-left: 10px;
}
.text {
  width: 35%;
  height: 100%;
  line-height: 250%;
}
.el-table {
  margin-top: 5%;
}
</style>
